import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons,
} from '@ng-bootstrap/ng-bootstrap';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { SheetState } from '../../../store/sheet/sheet.store';
import { Sheet } from '../../../store/sheet/sheet.actions';
import { COL_STATUSES } from '../../../constant';

@Component({
  selector: 'app-pg-col-dialog',
  templateUrl: './pickddi-pgcol-dialog.component.html',
  styleUrls: ['./pickddi-pgcol-dialog.component.css'],
})
export class PickddiPGColDialogComponent implements OnInit {
  closeResult: string = '';
  @ViewChild('content', { static: true }) content: any;
  private modalRef!: NgbModalRef;
  columns: any[] = [];
  unselected: boolean = false;

  @Select(SheetState.PickDdiColumns) columns$!: Observable<any>;

  constructor(
    private modalService: NgbModal,
    public dialogRef: MatDialogRef<PickddiPGColDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store
  ) {
    this.columns$.subscribe((columns) => {
      this.columns = columns.map((col: any) => ({ ...col }));
    });
  }

  ngOnInit() {
    this.open(this.content);
  }

  open(content: any) {
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });
    this.modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.onClose();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.onClose();
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onClose(): void {
    this.modalRef.dismiss();
    this.dialogRef.close();
  }

  toggleColumnVisibility(column: any) {
    if (this.isVisible(column)) {
      const index = column.status.indexOf(COL_STATUSES.DDS_COL);
      if (index !== -1) {
        column.status.splice(index, 1);
      }
    } else {
      column.status.push(COL_STATUSES.DDS_COL);
    }

    this.store.dispatch(new Sheet.UpdatePickDdiColumns(this.columns));
  }

  isVisible(column: any): boolean {
    return column.status.includes(COL_STATUSES.DDS_COL) ? true : false;
  }

  onSaveAs(): void {
    // this.store.dispatch(new Sheet.UpdatePickDdiColumns(this.columns));
    this.modalRef.dismiss();
    this.dialogRef.close();
  }

  drop(event: CdkDragDrop<any[]>): void {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
    this.store.dispatch(new Sheet.UpdatePickDdiColumns(this.columns));
  }
}
