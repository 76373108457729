<ng-template #content let-modal>
  <angular-window class="modal-col" style="background-color: white" [dragHolder]="header">
    <div class="modal-header" #header>
    </div>
    <div class="edit-item-container">
      <div cdkDropList (cdkDropListDropped)="drop($event)">
        <div *ngFor="let col of columns; let i = index" cdkDrag>
          <button class="col-item" mat-menu-item>
            <input type="checkbox" [checked]="isVisible(col)" (change)="toggleColumnVisibility(col)" class="checkbox"
              style="height: 10px; width: 10px" />
            <span>{{ col?.title }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="button-group">
      <div class="font-style Button" (click)="onSaveAs()">Save</div>
      <div type="button" class="font-style Button" (click)="onClose()">Cancel</div>
    </div>
  </angular-window>
</ng-template>