<ng-template #content let-modal >
  <angular-window style="background-color:white" [dragHolder]="header">
    <div class="modal-header" #header>
      <div class="header-title">
        <p class="form-title">Format Page</p>
      </div>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click'); dialogRef.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="form-card">
      <!-- <p class="form-title">Format Page</p> -->
      <form [formGroup]="formatPageForm">
        <div class="form-group">
          <label>Format ID:</label>
          <input type="text" formControlName="formatId" readonly class="custom-input">
        </div>
        <div class="form-group">
          <label>Format User:</label>
          <input type="text" formControlName="formatUser" class="custom-input" autofocus="false">
        </div>
        <div class="form-group">
          <label>Page ID:</label>
          <input type="text" formControlName="pageId" readonly class="custom-input">
        </div>
        <div class="form-group">
          <label>Nested Column:</label>
          <input type="text" formControlName="pageNestedColumn" class="custom-input">
        </div>
        <div class="form-group">
          <label>Freeze Column(s):</label>
          <input type="text" formControlName="pageFreezeColumn" class="custom-input">
        </div>
        <div class="form-group">
          <label>Expand Level:</label>
          <input type="text" formControlName="pageExpand" class="custom-input">
        </div>
        <div class="form-group">
          <label>Sort Formula:</label>
          <input type="text" formControlName="pageSort" class="custom-input">
        </div>
        <div class="form-group">
          <label>Filter Formula:</label>
          <input type="text" formControlName="pageFilter" class="custom-input">
        </div>
        <div class="form-group">
          <label>Level Set:</label>
          <input type="text" formControlName="pageLevelSet" class="custom-input">
        </div>
        <div class="form-group">
          <label>Search Set:</label>
          <input type="text" formControlName="pageSearchSet" class="custom-input">
        </div>
        <div class="form-group">
          <label>Font Style:</label>
          <input type="text" formControlName="pageFontStyle" (click)="openFontDialog()" class="custom-input">
        </div>
        <div class="form-group">
          <label>Comment:</label>
          <textarea formControlName="pageComment"></textarea>
        </div>
        <div class="form-group">
          <label>Transactions:</label>
          <input type="text" #transactionInput placeholder="Add transaction" class="custom-input" (keyup.enter)="addTransaction(transactionInput.value); transactionInput.value=''" readonly>
        </div>
        <div class="button-group">
        <div type="button" class="Button" (click)="onSave();">Save</div>
        <div type="button" class="Button" (click)="onCancel()">Cancel</div>
      </div>
      </form>
    </div>
  </angular-window>
</ng-template>