import { Component, OnInit, ViewChild, EventEmitter, Output, Input, AfterViewInit, HostListener, Renderer2, ElementRef, OnChanges, SimpleChanges, ViewChildren, QueryList } from '@angular/core';
import {  MatMenu, MatMenuPanel } from '@angular/material/menu';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { Edition, User } from '../../../modals/page.modal';
import { MenuBarService } from '../../../services/menu-bar-service/manu-bar.service';
import { PGEditionDialogComponent } from '../../../modals/pgedition-dialog/pgedition-dialog.component';
import { FormatPageDialogComponent } from '../../../modals/format-page-dialog/format-page-dialog.component';
import { ViewPageDialogComponent } from '../../../modals/view-page-dialog/view-page-dialog.component';
import { DialogDeleteComponent } from '../../../modals/dialog-delete/dialog-delete.component';
import { PGColDialogComponent } from '../../../modals/pgcol-dialog/pgcol-dialog.component';
import { PGUserDialogComponent } from '../../../modals/pguser-dialog/pguser-dialog.component';
import { AllPages_columns } from '../../../core/storage/AllPages_Columns.storage';
import { AllPagesDialogComponent } from '../../../modals/all-pages-dialog/all-pages-dialog.component';
import { Select, Store } from '@ngxs/store';
import { SheetState } from '../../../store/sheet/sheet.store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { FontStyle } from '../../../store/fontStyle/font.actions';
import { SheetService } from '../../../services/sheet-service/sheet.service';
import { Sheet } from '../../../store/sheet/sheet.actions';
import { EditAmountDialogComponent } from '../../../modals/edit-amount-dialog/edit-amount-dialog.component';
import { EditJSONDialogComponent } from '../../../modals/edit-json-dialog/edit-json-dialog.component';
import { AddEditTextComponent } from '../../../modals/add-edit-text/add-edit-text.component';
import { AddEditFormulaComponent } from '../../../modals/add-edit-formula/add-edit-formula.component';
import { PickDdiDialogueComponent } from '../../../modals/pick-ddi-dialogue/pick-ddi-dialogue.component';
interface ContextMenuItem {
  name: string;
  mode: 'View' | 'Format' | 'Edit'; // Restrict to specific modes
  isDimmed?: boolean; // Optional property to track dimmed state
}

declare const google: any;
@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss'],
})
export class MenuBarComponent implements OnInit, AfterViewInit {
  @Input() sheetSelected: any;
  @Output() dataExported: EventEmitter<void> = new EventEmitter<void>();
  @Output() columnFreezeToggled: EventEmitter<void> = new EventEmitter<void>();
  @Output() dataSorted: EventEmitter<void> = new EventEmitter<void>();
  @Input() currentSheetIndex!: number;
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger!: MatMenuTrigger;
  firstIncrement: boolean = true;
  @Select(SheetState.regions) regions$!: Observable<[]>;
  userMenu!: MatMenuPanel<any> | null;
  regionDropdown = [];
  currencies = ['US$', 'CN¥', 'EU€', 'GB£'];
  languageDropdownVisible = false;
  regionDropdownVisible = false;
  currencyDropdownVisible = false;
  userDialogVisible = false;
  tooltipVisible = false;
  currentTooltip = '';
  message: string = '';
  isLoggedIn = false;
  currentMode: 'View' | 'Format' | 'Edit' = 'View';
  sortStatus: string = 'Sort is Off';
  filterStatus: string = 'Filter is Off';
  isBlinking: boolean = true;
  sessionId!: string;
  uiRegion!: string;
  uiCurrency: any = 'EU€';
  dropdownVisible = false;
  columns: any[] = [];
  sheetData: any[] = [];
  pageFreezeColumn: any = 0;
  preferredLanguages: string[] = [];
  filteredLanguages: string[] = [];
  trigger: any;
  selectedLanguage: {} = { language: 'English', type: 'language' };
  tooltip: any = '';
  pageMenu: boolean = false;
  isChecked: boolean = false;
  lastEditionopen: any = 'Default';
  disabled: boolean = true;
  EditionCheckbox: boolean = false; // Tracks the state of the checkbox
  edition: string = '';
  @Select(SheetState.getFrozen) frozen$: Observable<any> | undefined;
  private unsubscribe$ = new Subject<void>();
  @Input() set sheet(data: any) {
    this.sheetData = data;
    this.menuBarService.setPages(this.sheetData);
  }
  ExpandLevels: any = { n: 99, x: null, y: null, z: null };
  constructor(
    private menuBarService: MenuBarService,
    private sheetService: SheetService,
    public dialog: MatDialog,
    private store: Store
  ) {
    this.filteredLanguages = this.preferredLanguages.length
      ? this.preferredLanguages
      : this.getAllGoogleLanguages();
  }

  ngOnInit() {
    this.menuBarService
      .getSessionId()
      .subscribe((sessionId) => (this.sessionId = sessionId));
    this.menuBarService.uiRegion.subscribe(
      (uiRegion) => (this.uiRegion = uiRegion)
    );
    this.menuBarService.isLoggedIn.subscribe(
      (isLoggedIn) => (this.isLoggedIn = isLoggedIn)
    );
    this.menuBarService.message.subscribe(
      (message) => (this.message = message)
    );
    this.menuBarService.currentMode.subscribe(
      (currentMode) => (this.currentMode = currentMode)
    );
    this.menuBarService.sortStatus.subscribe(
      (sortStatus) => (this.sortStatus = sortStatus)
    );
    this.menuBarService.filterStatus.subscribe(
      (filterStatus) => (this.filterStatus = filterStatus)
    );
    this.menuBarService.isBlinking.subscribe(
      (isBlinking) => (this.isBlinking = isBlinking)
    );

    if (!this.isChecked) {
      const bodyElement = document.body;
      bodyElement.classList.add('notranslate');
    }
    this.tooltip = Object.values(this.selectedLanguage)[0];
    this.selectedPage();
    let obj1 = { pageExpand: this.ExpandLevels };
    this.menuBarService.pageFormate.next(obj1);
    this.menuBarService.pageFormateReg.next(obj1);
    this.regions();
  }

  regions(): void {
    this.store.dispatch(new Sheet.getRegions());
    this.regions$.subscribe((res:any) => {
      if (res) {
        this.regionDropdown = res.map((region:any) => Object.values(region)[0]);
      }
    });
}

  onExpandToggle(event: MouseEvent): void {
    const target = event.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();
    const y = event.clientY - rect.top;
    let nValue = this.ExpandLevels.n.toString();
    if (!nValue || isNaN(parseFloat(nValue))) {
      nValue = '0';
      this.ExpandLevels.n = 0;
      return;
    } else if (nValue.startsWith('.')) {
      nValue = '0' + nValue;
    }
    const [integerPart, decimalPart] = nValue.split('.');
    let intValue = parseInt(integerPart, 10);
    const centralAreaHeight = rect.height * 0.2;
    if (y < rect.height / 2 - centralAreaHeight / 2) {
      if (this.firstIncrement) {
        this.firstIncrement = false;
      }
      intValue += 1;
    } else if (y > rect.height / 2 + centralAreaHeight / 2 && intValue > 0) {
      intValue = Math.max(0, intValue - 1);
    }
    this.ExpandLevels.n = decimalPart
      ? `${intValue}.${decimalPart}`
      : `${intValue}`;
    this.onExpandChange(this.ExpandLevels.n); // Send updated value
  }

  expandLevel(value: any) {
    const nValue = value || '0';
    const [integerPart, decimalPart] = nValue.toString().split('.');

    this.ExpandLevels.n = decimalPart
      ? `${integerPart}.${decimalPart}`
      : `${integerPart}`;
    this.onExpandChange(this.ExpandLevels.n);
  }

  onExpandChange(ExpandLevelss: string) {
    const level = this.parseExpandLevel(ExpandLevelss);
    this.menuBarService.Expand_Visibilt.next(level);
    let obj1 = { pageExpand: level };
    this.menuBarService.pageFormate.next(obj1);
  }

  parseExpandLevel(level: string): {
    n: number;
    x: number | null;
    y: number | null;
    z: number | null;
  } {
    let nValue = this.ExpandLevels.n
      ? parseInt(this.ExpandLevels.n.toString().split('.')[0])
      : 0;

    const match = level.match(
      /(\d+)(?:\.(1))?(?:(2))?(?:(3))?(?:\.(12|13|23|123))?/
    );
    if (!match) return { n: nValue, x: null, y: null, z: null }; // Default return if no match

    nValue = parseInt(match[1]); // Captures 'n'
    let xValue = match[2] ? 1 : null; // x is only 1 if matched
    let yValue = match[3] ? 2 : null; // y is only 2 if matched
    let zValue = match[4] ? 3 : null; // z is only 3 if matched

    // Handle combined cases for y and z
    if (match[5]) {
      if (match[5].includes('1')) xValue = 1; // If part of "12", "13", or "123"
      if (match[5].includes('2')) yValue = 2; // If part of "12", "23", or "123"
      if (match[5].includes('3')) zValue = 3; // If part of "13", "23", or "123"
    }

    return {
      n: nValue,
      x: xValue,
      y: yValue,
      z: zValue,
    };
  }

  toggleFreeze(event: MouseEvent): void {
    const target = event.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();
    const y = event.clientY - rect.top;
    const centralAreaHeight = rect.height * 0.2;
    if (y < rect.height / 2 - centralAreaHeight / 2) {
      this.pageFreezeColumn += 1;
      let obj1 = { pageFreezeColumn: this.pageFreezeColumn };

      this.menuBarService.pageFormate.next(obj1);
    } else if (
      y > rect.height / 2 + centralAreaHeight / 2 &&
      this.pageFreezeColumn > 0
    ) {
      this.pageFreezeColumn = Math.max(0, this.pageFreezeColumn - 1);
      let obj2 = { pageFreezeColumn: this.pageFreezeColumn };

      this.menuBarService.pageFormate.next(obj2);
    }
  }

  setLanguage(language: string | null) {
    const bodyElement = document.body;
    bodyElement.classList.remove('notranslate');
    this.selectedLanguage = {
      language: language || 'English',
      type: 'language',
    };
    this.tooltip = Object.values(this.selectedLanguage)[0];
    if (language) {
      this.translatePage(language);
    } else {
      this.translatePage('en');
    }
  }

  ngAfterViewInit() {
    const selectElement = document.querySelector(
      '.goog-te-combo'
    ) as HTMLSelectElement;
    if (selectElement) {
      this.filteredLanguages.forEach((lang) => {
        const optionElement = Array.from(selectElement.options).find(
          (option) => option.text === lang
        );
        if (optionElement) {
          optionElement.style.display = 'block';
        }
      });
    }
    this.edition = this.menuBarService?.getEdition();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    this.pageMenu = false;
  }

  translatePage(language: string) {
    const selectElement = document.querySelector(
      '.goog-te-combo'
    ) as HTMLSelectElement;
    if (selectElement) {
      const langValue = this.getLanguageValue(language);
      selectElement.value = langValue;
      selectElement.dispatchEvent(new Event('change'));
    }
  }

  getLanguageValue(language: string): string {
    const languageMap: { [key: string]: string } =
      this.menuBarService.languageListKeys;
    return languageMap[language] || 'en';
  }

  getAllGoogleLanguages(): string[] {
    return this.menuBarService.languageList;
  }

  previousRes: any = null;
  selectedPage() {
    this.menuBarService.selectedId.subscribe((res) => {
      if (this.previousRes !== res) {
        this.disabled = this.hasChildren(res, this.menuBarService.AllPageData);
        if (this.EditionCheckbox) {
          this.EditionCheckbox = false;
        }
        this.previousRes = res;
      }
    });
  }

  hasChildren(pageId: any, pages: any): boolean {
    const page = pages.find((p: { page_id: any }) => p.page_id == pageId);
    if (page) {
      if (page._children && page._children.length > 0) {
        return true;
      }
    }
    return pages.some(
      (p: { _children: any }) =>
        p._children && this.hasChildren(pageId, p._children)
    );
  }

  refreshPage() {
    this.menuBarService.refreshPage();
  }

  blinkRefresh() {
    this.menuBarService.blinkRefresh();
  }

  showCurrencyOptions() {
    this.menuBarService.showCurrencyOptions();
  }

  onRightClick(event: MouseEvent) {
    event.preventDefault();
    this.matMenuTrigger.openMenu();
  }

  handleCheckboxChange(event: any) {
    this.EditionCheckbox = event.target.checked;
    this.menuBarService.handleCheckboxChange(event);
  }

  toggleRegionDropdown() {
    this.menuBarService.toggleRegionDropdown();
  }

  toggleCurrencyDropdown() {
    this.menuBarService.toggleCurrencyDropdown();
  }

  toggleUserDialog() {
    this.menuBarService.toggleUserDialog();
  }

  showTooltip(menuItem: string) {
    this.menuBarService.showTooltip(menuItem);
  }

  hideTooltip() {
    this.menuBarService.hideTooltip();
  }

  setRegion(region: string) {
    this.menuBarService.setRegion(region);
  }

  setCurrency(currency: string) {
    this.uiCurrency = currency;
    this.menuBarService.setCurrency(currency);
  }

  toggleAutoTranslate(event: Event) {
    if (this.isChecked) {
      const bodyElement = document.body;
      bodyElement.classList.remove('notranslate');
      const selectElement = document.querySelector(
        '.goog-te-combo'
      ) as HTMLSelectElement;
      // Check if the element is found and is an HTMLSelectElement
      if (selectElement) {
        // Get the selected value
        const selectedLanguage = selectElement.value;
        // Output the selected value to the console (or use it as needed)
        Object.values(this.menuBarService.languageListKeys).map(
          (code, index) => {
            if (selectedLanguage == code) {
              this.tooltip = Object.keys(this.menuBarService.languageListKeys)[
                index
              ];
            }
          }
        );
      } else {
        console.error('Select element not found.');
      }
    }
  }

  login() {
    this.menuBarService.login();
  }

  openProfile() {
    this.menuBarService.openProfile();
  }

  logout() {
    this.menuBarService.logout();
  }

  register() {
    this.menuBarService.register();
  }

  closeDialog() {
    this.menuBarService.closeDialog();
  }

  selectRegion = this.regionDropdown[0];
  frozen:any=0;
  async showCountryRegion () {
    await this.frozen$?.pipe(takeUntil(this.unsubscribe$)).subscribe(data=>{
      this.frozen=data;
    })
    const dialogRef = this.dialog.open(PickDdiDialogueComponent, {
      width: 'auto',
      height:'900px',
      panelClass:'pick-ddl',
      data:{data:this.frozen}
    });
  }

  currentPgMode: 'View' | 'Format' | 'Edit' = 'View';
  contextMenuItems: ContextMenuItem[] = [
    { name: 'Format', mode: 'Format' },
    { name: 'Edit', mode: 'Edit' },
    { name: 'View', mode: 'View' },
  ];

  setViewMode(mode: 'View' | 'Format' | 'Edit') {
    this.currentPgMode = mode;
    this.updateContextMenuColors();
  }

  updateContextMenuColors() {
    this.contextMenuItems.forEach((item) => {
      const isDimmed = this.currentPgMode !== item.mode;
      item.isDimmed = isDimmed;
    });
  }

  toggleSort() {
    this.menuBarService.toggleSort();
  }

  toggleFilter() {
    this.menuBarService.toggleFilter();
  }

  selectedEdition: Edition | null = null;
  tooltipContent: string = '';

  openEditionDialog(): void {
    const dialogRef = this.dialog.open(PGEditionDialogComponent, {
      width: '400px',
      data: { selectedEdition: this.selectedEdition },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedEdition = result;
      }
    });
  }
  openColDialog() {
    const dialogRef = this.dialog.open(PGColDialogComponent, {
      width: '750px',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  toggleCompare() {
    this.menuBarService.toggleCompare();
  }
  selectedUserOption: User | null = null;
  openUserDialog(): void {
    const dialogRef = this.dialog.open(PGUserDialogComponent, {
      width: '400px',
      data: { selectedUserOption: this.selectedUserOption },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedUserOption = result;
      }
    });
  }

  toggleTimer() {
    this.menuBarService.toggleTimer();
  }

  formatPage() {
    const dialogRef = this.dialog.open(FormatPageDialogComponent, {
      data: {
        data: { title: 'Formal Page', id: this.menuBarService.getPageId() },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(new FontStyle.SetFontStyle(result));
        this.expandLevel(result?.pageExpand);
        this.pageFreezeColumn = result?.pageFreezeColumn;
        this.menuBarService.pageFormate.next(result);
      }
    });
  }

  deletePage() {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      data: {
        data: "shall be Deleted? Click 'Delete' to confirm ",
        name: 'Page',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'Page') {
        // Emit value to delete page using menu service
        this.menuBarService.deletePage.next(true);

        this.menuBarService.deleteSheetPage();
      }
    });
  }
  celldata: any;
  viewPage() {
    let formatData = {
      formatUser: 'User ABC',
      pageNestedColumn: 'Nested Column Data',
      pageFreezeColumn: 'Freeze Column Data',
      pageExpand: 'Expand Data',
      pageSort: 'Sort Data',
      pageFilter: 'Filter Data',
      pageLevelSet: 'Level Set Data',
      pageSearchSet: 'Search Set Data',
      pageFontStyle: 'Font Style Data',
      pageComment: 'Comment Data',
      pageTransactions: 'Transaction Data',
    };
    this.menuBarService.pgRow.subscribe((res) => {
      this.celldata = res;
    });
    const dialogRef = this.dialog.open(ViewPageDialogComponent, {
      data: {
        cell: this.celldata,
        format: formatData,
        columns: AllPages_columns,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  openContextMenu(event: MouseEvent, menuId: string): void {
    event.preventDefault();
    if (event && menuId == 'pageMenu') {
      this.pageMenu = !this.pageMenu;
    }
  }

  openAllPagesDialog() {
    this.store.dispatch(new Sheet.PageButtonPopupData('1000000001'));
    const dialogRef = this.dialog.open(AllPagesDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.handlePageSelection(result); // Process the selected page
      }
    });
  }
  handlePageSelection(selectedPage: any) {
    const lastEdition = this.menuBarService.getLastOpenedEdition(
      selectedPage?.page?.page_id
    );
    if (lastEdition) {
      this.lastEditionopen = lastEdition?.page_name;
      this.menuBarService.openPage(lastEdition); // Open last edition page in a new tab
    } else {
      this.lastEditionopen = 'Default';
      this.menuBarService.openPage(selectedPage); // Open the selected page itself
    }
    if (selectedPage?.selectedPage == 'child') {
      this.lastEditionopen = selectedPage.page.page_name;
    }
  }
  OpenAmountDialoag(){
    let dialogRef = this.dialog.open(EditAmountDialogComponent, {
      data: { value: 'this.currentCell.getValue()' },
    });
  }
  OpenJSONDialog(){
    let dialogRef = this.dialog.open(EditJSONDialogComponent, {
      data: { value: 'this.currentCell.getValue()' },
    });
  }
  OpenTextDialog(){
    let dialogRef = this.dialog.open(AddEditTextComponent, {
      data: { value: 'this.currentCell.getValue()' },
    });
  }
  OpenFormulaDialog(){
    let dialogRef = this.dialog.open(AddEditFormulaComponent, {
      data: { value: 'this.currentCell.getValue()' },
    });
  }
}