import { Component, ViewChild, OnInit, Inject, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
// import { WProofreader } from '@webspellchecker/wproofreader-ckeditor5';  // TODO Liscence Awaiting

import {
	ClassicEditor,
	AccessibilityHelp,
	Autoformat,
	AutoImage,
	Autosave,
	BlockQuote,
	Bold,
	CKBoxImageEdit,
	CloudServices,
	Code,
	Essentials,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	FullPage,
	GeneralHtmlSupport,
	Heading,
	Highlight,
	HtmlComment,
	HtmlEmbed,
	ImageBlock,
	ImageCaption,
	ImageInline,
	ImageInsert,
	ImageInsertViaUrl,
	ImageResize,
	ImageStyle,
	ImageTextAlternative,
	ImageToolbar,
	ImageUpload,
	Indent,
	IndentBlock,
	Italic,
	Link,
	LinkImage,
	List,
	ListProperties,
	MediaEmbed,
	PageBreak,
	Paragraph,
	PasteFromOffice,
	PictureEditing,
	RemoveFormat,
	SelectAll,
	ShowBlocks,
	SourceEditing,
	SpecialCharacters,
	SpecialCharactersArrows,
	SpecialCharactersCurrency,
	SpecialCharactersEssentials,
	SpecialCharactersLatin,
	SpecialCharactersMathematical,
	SpecialCharactersText,
	Strikethrough,
	Subscript,
	Superscript,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	TextPartLanguage,
	TextTransformation,
	Title,
	TodoList,
	Underline,
	Undo,
  FindAndReplace,
	type EditorConfig,
  AutoLink,
  Base64UploadAdapter,
  CKBox,
  CKFinder,
  CKFinderUploadAdapter,
  CodeBlock,
  HorizontalLine,
  Style,

} from 'ckeditor5';


import { CaseChange, ExportPdf, ExportWord, FormatPainter, ImportWord, SlashCommand, TableOfContents, Template } from 'ckeditor5-premium-features'; //TODO Unsed Things are awaiting liscence
@Component({
  selector: 'dialog-add',
  templateUrl: './dialog-add.component.html',
  styleUrls: ['./dialog-add.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DialogAddComponent implements OnInit {
  form!: FormGroup;
  closeResult: string = '';
  private modalRef!: NgbModalRef;
  valueChanged: string | null = null;
  selectedValue: string | null = null;
  selectedIndex: number = -1;
  finalValue: string = '';
  @ViewChild('content', { static: true }) content: any;

  constructor(
    private modalService: NgbModal,
    public dialogRef: MatDialogRef<DialogAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.open(this.content);
    this.form = this.fb.group({
      languageGroups: this.fb.array([this.createLanguageGroup()])
    });
    console.log(this.data);
  } 
 // CKEditor needs the DOM tree before calculating the configuration.
 public isLayoutReady = false;
 public Editor = ClassicEditor;
 public config: EditorConfig = {}; // CKEditor needs the DOM tree before calculating the configuration.
 public ngAfterViewInit(): void {
   this.config = {
  //    wproofreader: {
  //     serviceProtocol: 'https',
  //     serviceHost: 'localhost',
  //     servicePort: '443',
  //     servicePath: 'virtual_directory/api/',
  //     srcUrl: 'https://host_name/virtual_directory/wscbundle/wscbundle.js'
  // },
  plugins: [
    CloudServices,
    Undo,
    PageBreak,
    List,
    HorizontalLine,
    SelectAll,
    Style,
    ImportWord,
    CKBox,
    // Template,
    // WProofreader,
    CKFinder, CKFinderUploadAdapter,
    // TableOfContents,                               TODO: Liscence Required for commented Plugins.
    FindAndReplace,
    // CaseChange,
    CodeBlock,
    Autoformat,
    Base64UploadAdapter,
    // FormatPainter,
    AutoImage,
    Autosave,
    BlockQuote,
    Bold,
    CKBoxImageEdit,
    Code,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    FullPage,
    GeneralHtmlSupport,
    Heading,
    Highlight,
    HtmlComment,
    HtmlEmbed,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsert,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    AutoLink,
    LinkImage,
    List,
    ListProperties,
    MediaEmbed,
    PageBreak,
    Paragraph,
    PasteFromOffice,
    PictureEditing,
    RemoveFormat,
    SelectAll,
    ShowBlocks,
    SourceEditing,
    SpecialCharacters,
    SpecialCharactersArrows,
    SpecialCharactersCurrency,
    SpecialCharactersEssentials,
    SpecialCharactersLatin,
    SpecialCharactersMathematical,
    SpecialCharactersText,
    Strikethrough,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextPartLanguage,
    TextTransformation,
    Title,
    TodoList,
    Underline,
    Undo,
    ExportWord,
    ExportPdf
  ],
  toolbar: {
    items: [
      'undo',
      'redo',
      '|',
      'importWord',
      'exportWord',
      'exportPdf',
      '|',
      'formatPainter',
      'caseChange',
      'findAndReplace',
      'selectAll',
      'wproofreader',
      '|',
      'insertTemplate',
      'tableOfContents',
      '|',
      'link',
      'insertImage',
      'ckfinder',
      'insertTable',
      'blockQuote',
      'mediaEmbed',
      'codeBlock',
      'pageBreak',
      'horizontalLine',
      'specialCharacters',
      'heading',
         'style',
      'bold',
       'italic',
       'underline',
       'strikethrough',
       'fontFamily',
        'fontSize',
       'removeFormat',
       '|',
       'alignment',
       '|',
      'bulletedList',
      'numberedList',
      'outdent',
       'indent',
    ],
    shouldNotGroupWhenFull: true,
    
  },
  caseChange: {
    titleCase: {
        excludeWords: [ 'a', 'an', 'and', 'as', 'at', 'but', 'by', 'en', 'for', 'if', 'in',
            'nor', 'of', 'on', 'or', 'per', 'the', 'to', 'vs', 'vs.', 'via' ]
    }
},
  style: {
    definitions: [
        // Styles definitions.
        // ...
    ]
},

  
  fontFamily: {
       supportAllValues: true
     },
     fontSize: {
       options: [10, 12, 14, 'default', 18, 20, 22],
       supportAllValues: true
     },
     
     heading: {
       options: [
         {
           model: 'paragraph',
           title: 'Paragraph',
           class: 'ck-heading_paragraph'
         },
         {
           model: 'heading1',
           view: 'h1',
           title: 'Heading 1',
           class: 'ck-heading_heading1'
         },
         {
           model: 'heading2',
           view: 'h2',
           title: 'Heading 2',
           class: 'ck-heading_heading2'
         },
         {
           model: 'heading3',
           view: 'h3',
           title: 'Heading 3',
           class: 'ck-heading_heading3'
         },
         {
           model: 'heading4',
           view: 'h4',
           title: 'Heading 4',
           class: 'ck-heading_heading4'
         },
         {
           model: 'heading5',
           view: 'h5',
           title: 'Heading 5',
           class: 'ck-heading_heading5'
         },
         {
           model: 'heading6',
           view: 'h6',
           title: 'Heading 6',
           class: 'ck-heading_heading6'
         }
       ]
     },
     htmlSupport: {
       allow: [
         {
           name: /^.*$/,
           styles: true,
           attributes: true,
           classes: true
         }
       ]
     },
     image: {
       toolbar: [
         'toggleImageCaption',
         'imageTextAlternative',
         '|',
         'imageStyle:inline',
         'imageStyle:wrapText',
         'imageStyle:breakText',
         '|',
         'resizeImage',
         '|',
         'ckboxImageEdit'
       ]
     },
     
     initialData:
       '',
     link: {
       addTargetToExternalLinks: true,
       defaultProtocol: 'https://',
       decorators: {
         toggleDownloadable: {
           mode: 'manual',
           label: 'Downloadable',
           attributes: {
             download: 'file'
           }
         }
       }
     },
     list: {
       properties: {
         styles: true,
         startIndex: true,
         reversed: true
       }
     },
     placeholder: 'Enter Rich Text',
     table: {
       contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
     }
   };


   this.isLayoutReady = true;
   this.changeDetector.detectChanges();
 }


  onValueChange({ editor }: ChangeEvent,index:number): void {
    this.checkValues(index);
  }

  onSelect(value: string, index: number): void {
    this.checkValues(index);
    this.updateRichTextField(index);
  }
  
  checkValues(index: number): void {
    // Only add a new group if the current group is valid
    if (this.isCurrentGroupValid()) {
      this.addLanguageGroup();
    }
    console.log(this.isCurrentGroupValid());
  }

  get languageGroups(): FormArray {
    return this.form.get('languageGroups') as FormArray;
  }

  createLanguageGroup(): FormGroup {
    const group = this.fb.group({
      language: ['', Validators.required],
      richText: [{ value: '', disabled: false }, Validators.required] // Initialize as disabled
    });

    // Update richText field based on language value
    group.get('language')?.valueChanges.subscribe(value => {
      if (value) {
        group.get('richText')?.enable();
      } else {
        group.get('richText')?.disable();
      }
    });

    return group;
  }

  // Check if the current form group is valid
  isCurrentGroupValid(): boolean {
    const currentGroup = this.languageGroups.at(this.languageGroups.length - 1) as FormGroup;
    return currentGroup.valid;
  }

  addLanguageGroup() {
    // Add a new language group only if the current group is valid
    if (this.isCurrentGroupValid()) {
      this.languageGroups.push(this.createLanguageGroup());
    }
  }

  removeLanguageGroup(index: number) {
    if (this.languageGroups.length > 1) {
      this.languageGroups.removeAt(index);
    }
  }

  updateRichTextField(index: number) {
    const group = this.languageGroups.at(index) as FormGroup;
    const languageValue = group.get('language')?.value;

    if (languageValue) {
      group.get('richText')?.enable();
    } else {
      group.get('richText')?.disable();
    }
  }

  open(content: any) {
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });
    this.modalRef.result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        this.onCancel();
        return 'by pressing ESC';
      case ModalDismissReasons.BACKDROP_CLICK:
        this.onCancel();
        return 'by clicking on a backdrop';
      default:
        return `with: ${reason}`;
    }
  }

  onSave(): void {
    this.dialogRef.close(this.form.value);
    this.modalRef.dismiss('Save click');
  }

  onCancel(): void {
    this.dialogRef.close(false);
    this.modalRef.dismiss('Cancel click');
  }
}
