<ng-template #content let-modal>
    <angular-window class="modal-edition" style="background-color:white" [dragHolder]="header">
      <div class="modal-header" #header>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');dialogRef.close()">
          <img class="cross-icon" src="../../../assets/Icons/cross-icon.svg" alt="cross-Icon"
          (click)="onClose()">
        </button>
      </div>
      <div class="edit-item-container">
        <div class="button-group">
            <div class="font-style Button" (click)="onOpen()">Open Edition</div>
          <div  class="font-style Button" (click)="onSaveAs()" >Save</div>
          <div type="button" class="font-style Button" (click)="onClose()" >Cancel</div>
        </div>
      </div>
    </angular-window>
  </ng-template>