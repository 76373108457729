<div class="tab-container">
  <div
    *ngFor="let sheet of sheets; let i = index"
    class="tab"
    [class.active]="i === currentSheetIndex"
  >
    <app-tooltip
      tooltip="Page Name: {{ sheet.page_name }}
    Page Edition: {{ sheet?.page_edition }}
    Page Comment: {{ sheet?.page_comment }}"
    >
      <div class="tab-contain">
        <div
          ngbDropdown
          class="d-inline-block"
          style="height: 19px; line-height: 18px"
        >
          <button
            class="tab-button"
            (keydown.Shift.Tab)="onShiftTabKeyPress($event, i)"
            (keydown.Tab)="onKeyDown($event, i)"
            (click)="selectSheet(i)"
          >
            {{ sheet.page_name }}
          </button>
        </div>
        <div class="crossIcon" (click)="deleteSheet(i)">
          <img
            [src]="
              i === currentSheetIndex
                ? '../../../../../../assets/cross-icon.svg'
                : '../../../../../../assets/black-cross-icon.svg'
            "
            class="cross-icon"
          />
        </div>
      </div>
    </app-tooltip>
  </div>
  <div class="add-sheet" (click)="addSheet()">
    <img src="../../../../../../assets/plus.svg" class="plus-icon" />
  </div>
</div>
