<div #tabulatorDiv></div>
<div *ngIf="errorMessage" id="errorMessage" style="color: red;">
</div>
<div id="dropdownMenu" class="dropdown-menu">
  <ul style="padding: 0px;">
    <li class="no-color" [ngClass]="{disabled: disabled}"  (click)="!disabled? addItem($event):''">Add Item</li>
    <li class="no-color" [ngClass]="{disabled: !disabled}" (click)="disabled? editItem($event):''">Edit Item</li>
    <li class="no-color" (click)="deleteItem()">Delete Item</li>
    <li class="no-color"  id="format-local-item">Format Local-Item</li>
    <li class="no-color" (click)="formatSharedItem()">Format Shared-Item</li>
    <li class="no-color" id="format-local-cell">Format Local-Cell</li>
    <li class="no-color" (click)="formatSharedCell()">Format Shared-Cell</li>
    <li class="no-color" (click)="viewCell()">View Cell</li>
    <li class="no-color" (click)="viewItem()">View Item</li>
  </ul>
</div>

<div class="dropdown-container" *ngIf="showDropdown">
  <p (click)="formatLocalCol()" class="dropdown-menu-item" style=" height: 16px;margin: 0px !important;  padding: 2px 0px 0px 0px;">Format Local-Column</p>
  <p  (click)="formatSharedCol()" class="dropdown-menu-item" style="height: 16px;margin: 0px !important;  padding: 2px 0px 0px 0px;">Format Shared-Column</p>
  <p (click)="deleteColumn()" class="dropdown-menu-item" style="height: 16px;margin: 0px !important;  padding: 2px 0px 0px 0px;">Delete Column</p>
  <p (click)="viewColumn()" class="dropdown-menu-item" style="height: 16px;margin: 0px !important; padding: 2px 0px 0px 0px;">View Column</p>
</div>