// These urls for RouterModule
export const ngRoutes = {
    account: {
        url: '',
        childrens: {
            login: 'login',
            home: 'home',
        }
    },
}

// These urls for anchors or routerLink
export const AnchorURLs = {
    account: {
        login: `/${ngRoutes.account.url}/${ngRoutes.account.childrens.login}`,
    }
}