import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxsModule } from '@ngxs/store';
import { SheetState } from './store/sheet/sheet.store';
import { environment } from '../envirnoments/environment';
import { ApiInterceptor } from './core/interceptor/api.interceptor';
import { ItemState } from './store/item/item.store';
import { LanguageState } from './store/language/language.store';
import { FontState } from './store/fontStyle/font.store';
import { FormatState } from './store/format/format.store';


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    importProvidersFrom(
      HttpClientModule,
      NgxsModule.forRoot([SheetState,ItemState,LanguageState,FontState,FormatState], {
        developmentMode: !environment,
      })
    ),
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
  ],
};
