import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons,
} from '@ng-bootstrap/ng-bootstrap';
import { MenuBarService } from '../../services/menu-bar-service/manu-bar.service';
import { Select, Store } from '@ngxs/store';
import { Sheet } from '../../store/sheet/sheet.actions';
import { Observable } from 'rxjs';
import { SheetState } from '../../store/sheet/sheet.store';

@Component({
  selector: 'app-add-row-dialog',
  templateUrl: './add-row-dialog.component.html',
  styleUrls: ['./add-row-dialog.component.css'],
})
export class AddRowDialogComponent {
  closeResult: string = '';
  @ViewChild('content', { static: true }) content: any;
  private modalRef!: NgbModalRef;
  timer: number = 30; // 30 seconds countdown
  countDownInterval: any;
  pageId: any = '';
  rowData: any;
  @Select(SheetState.addRow) rowData$: Observable<any> | undefined;

  constructor(
    private modalService: NgbModal,
    public dialogRef: MatDialogRef<AddRowDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private menubarService: MenuBarService,
    private store: Store
  ) {}

  ngOnInit() {
    this.menubarService.selectedId.subscribe((res) => {
      this.pageId = res;
    });
    this.open(this.content);
    this.rowData = this.data.rowData.getData();
  }

  open(content: any) {
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });
    this.modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.onCancel();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.onCancel();
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onSave(): void {
    if (this.pageId == '1000000001') {
      this.generatePgRow();
    } else if (this.pageId == '1000000006') {
      this.generateCol();
    }
  }

  generatePgRow() {
    let rowLevel: number = 1;
    let parentRow: any = null;
    let siblingRow: any = null;
    if (this.data.rowDetails === 'Add Previous Row') {
      if (this.data?.siblingInfo) {
        siblingRow = this.data?.siblingInfo?.row;
      } else {
        siblingRow = this.rowData.row;
      }
      rowLevel = this.rowData?.RowLevel;
      parentRow = this.rowData?.ParentRow?.Row || null;
    } else if (this.data.rowDetails === 'Add Next Row') {
      if (this.data?.siblingInfo) {
        siblingRow = this.data?.siblingInfo?.row;
      }
      parentRow = this.rowData?.ParentRow?.Row || null;
      rowLevel = this.rowData?.RowLevel;
    } else if (this.data?.rowDetails === 'Add Child Row') {
      if (this.data?.siblingInfo) {
        siblingRow = null;
      }
      parentRow = this.rowData?.row;
      rowLevel = this.rowData?.RowLevel + 1;
    }

    let Row = {
      Pg: this.pageId,
      RowLevel: rowLevel,
      ParentRow: parentRow,
      SiblingRow: siblingRow,
    };
    this.store.dispatch(new Sheet.AddRow(Row)).subscribe(() => {
      this.rowData$?.subscribe((res) => {
        if (res.createdRow) {
          this.modalRef.dismiss('Save click');
          this.dialogRef.close(res);
        }
      });
    });
  }

  generateCol() {
    let col = {
      Pg: this.pageId,
      RowLevel: 1,
    };

    this.store.dispatch(new Sheet.addColumn(col)).subscribe(() => {
      this.rowData$?.subscribe((res) => {
        if (res.createdRow) {
          this.modalRef.dismiss('Save click');
          this.dialogRef.close(res);
        }
      });
    });
  }

  onCancel(): void {
    this.dialogRef.close();
    this.modalRef.dismiss('Cancel click');
  }
}
