import { MenuBarService } from './../../../services/menu-bar-service/manu-bar.service';
import { Component } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';

@Component({
  selector: 'app-main-page',
  standalone: true,
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css'],
  imports: [SharedModule],
})
export class MainPageComponent {
/**
 * Remove the static code.
 * 
 * TODO: Remove the static code.
 */
  sheets: any[] = ([...this.menuBarService.AllPageData])
  currentSheetIndex = 0;
constructor(private menuBarService:MenuBarService){}
/**
 * Selects a sheet by updating the current sheet index.
 * 
 * This method is typically used as an event handler for child components
 * to notify the parent component about the selected sheet index.
 * 
 * @param {number} index - The index of the selected sheet.
 */
  public handleSheetSelected(index: number) {
    this.currentSheetIndex = index;
  }

/**
 * Deletes a sheet by removing it from the sheets array
 * and adjusting the current sheet index if necessary.
 * 
 * @param {number} index - The index of the sheet to be deleted.
 */
  handleSheetDeleted(index: number) {
    // Remove the sheet at the given index
    this.sheets.splice(index, 1);
    // Adjust currentSheetIndex if needed
    if (this.currentSheetIndex >= this.sheets.length) {
      this.currentSheetIndex = this.sheets.length - 1;
    }
  }

/**
 * Adds a new sheet.
 * 
 * TODO: Implement any necessary logic that should be executed when a new sheet is added.
 * Currently, no action is required as the new sheet is automatically added to the sheets array.
 */
  handleSheetAdded() {
    // No need to do anything here, the new sheet is automatically added to sheets array
  }
}
