<ng-template #content let-modal>
  <angular-window class="add-item-modal" style="background-color:white"  [dragHolder]="header">
    <div class="modal-header" #header>
      <span class="heading">Edit-URL-dialog</span>
    </div>
    <div class="edit-item-container">
      <form>
        <div class="form-group">
          <label class="font-style">Enter URL</label>
          <input 
            type="text" 
            placeholder="Input" 
            class="font-style input-field" 
            [(ngModel)]="url" 
            name="url"
            (input)="onUrlChange()">
        </div>
        <div *ngIf="isValidUrl" class="iframe-container">
          <iframe id="url-preview" class="iframe-content"></iframe>
        </div>
        <div class="button-group">
          <div class="Button" type="button" (click)="onSave()">Save</div>
          <div type="button" class="Button" (click)="onCancel()">Cancel</div>
        </div>
      </form>
     
    </div>
  </angular-window>
</ng-template>
