import { Injectable } from "@angular/core";
import { EditItemStateModel, ItemStateModel } from "./item.statemodel";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { ApiService } from "../../services/api-service/api.service";
import { Item } from "./item.actions";
import { tap } from "rxjs";

@State<ItemStateModel>({
  name: 'item',
  defaults: {
    itemData:null
  },
})

@Injectable()
export class ItemState {
  constructor(private apiService: ApiService) {}

  @Selector([ItemState])
  static getItemData(state: ItemStateModel) {
    return state.itemData;
  }

  @Selector([ItemState])
  static getEditItemData(state: EditItemStateModel) {
    return state.editItemData;
  }

  @Action(Item.AddItemData)
  addItemData(
    ctx: StateContext<ItemStateModel>,
    action: Item.AddItemData,
  ) {
    return this.apiService.addItem(action.payload).pipe(
      tap((data: any) => {
        const state = ctx.getState();
        const stateModel: ItemStateModel = {
          itemData:data
        }
        // Update the existing state
        ctx.setState({
          ...state,
          ...stateModel
        });
      })
    );   
  }

  @Action(Item.GetItemDropdown)
  getItemDropdown(
    ctx: StateContext<ItemStateModel>,
    action: Item.GetItemDropdown,
  ) {
    return this.apiService.getItemDropdown(action.payload).pipe(
      tap((data: any) => {
        const state = ctx.getState();
      })
    );   
  }

  @Action(Item.UpdateItemThroughDropdown)
  updateItemThroughDropdown(
    ctx: StateContext<ItemStateModel>,
    action: Item.UpdateItemThroughDropdown,
  ) {
    return this.apiService.updateItemThroughDropdown(action.payload).pipe(
      tap((data: any) => {
        const state = ctx.getState();
      })
    );   
  }

  @Action(Item.AddItemThroughDropdown)
  addItemThroughDropdown(
    ctx: StateContext<ItemStateModel>,
    action: Item.AddItemThroughDropdown,
  ) {
    return this.apiService.addItemThroughDropdown(action.payload).pipe(
      tap((data: any) => {
        const state = ctx.getState();
      })
    );   
  }
  @Action(Item.EditItemData)
  editItemData(
    ctx: StateContext<EditItemStateModel>,
    action: Item.EditItemData,
  ) {
    return this.apiService.editItem(action.payload).pipe(
      tap((data: any) => {
        const state = ctx.getState();
        const stateModel: EditItemStateModel = {
          editItemData:data
        }
        // Update the existing state
        ctx.setState({
          ...state,
          ...stateModel
        });
      })
    );
  }
}