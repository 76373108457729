import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { tap } from "rxjs";
import { FormatStateModel } from "./format.statemodel";
import { Format } from "./format.actions";
import { ApiService } from "../../services/api-service/api.service";

@State<FormatStateModel>({
    name: 'format',
    defaults: {
     formatLocalCell:null
    },
  })

  @Injectable()
  export class FormatState {
    constructor(private apiService: ApiService) {}

    @Selector([FormatState])
    static getFormatLocalCell(state: FormatStateModel) {
      return state.formatLocalCell;
    }

    @Action(Format.FormatLocalCell)
    formatLocalCell(
      ctx: StateContext<FormatStateModel>,
      action: Format.FormatLocalCell
    ) {
      
      return this.apiService.formatLocalCell(action.payload).pipe(
        tap((data: any) => {
          // Retrieve the current state
          const state = ctx.getState();
        })
      );
    }
}