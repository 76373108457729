<ng-template #content let-modal>
  <angular-window class="resizable-dialog" style="background-color: white" [dragHolder]="header">
    <div class="modal-header" #header>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click'); dialogRef.close()"
      >
        <img
          class="cross-icon"
          src="../../../assets/Icons/cross-icon.svg"
          alt="cross-Icon"
          (click)="onClose()"
        />
      </button>
    </div>
    <div class="edit-item-container">
      <div cdkDropList (cdkDropListDropped)="drop($event)">
        <div *ngFor="let col of columns; let i = index" cdkDrag>
          
          <div class="col-item" >
            <span style="width: 12px;">&#9776;</span>
            <input type="checkbox" [checked]="isVisible(col)" (change)="toggleColumnVisibility(col)" class="checkbox" class="checkbox-style"/>
            <span>{{ col?.title }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="button-group">
      <div class="font-style Button" (click)="onSaveAs()">Save</div>
      <div type="button" class="font-style Button" (click)="onClose()">Cancel</div>
    </div> -->
  </angular-window>
</ng-template>