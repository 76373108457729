import { Routes } from '@angular/router';
import { LoginComponent } from './modules/auth/login/login.component';
import { ngRoutes } from './core/routes/ng.routes';
import { MainPageComponent } from './modules/main/main-page/main-page.component';
 
export const routes: Routes = [
    // { path: '', redirectTo: ngRoutes.account.childrens.login, pathMatch: 'full' },
    // { path: ngRoutes.account.childrens.login , component: MainPageComponent },
    { path: '', redirectTo: ngRoutes.account.childrens.home, pathMatch: 'full' },
    { path: ngRoutes.account.childrens.home , component: MainPageComponent },
];


