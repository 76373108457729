import {
  Component,
  Input,
  ElementRef,
  HostListener,
  Renderer2,
  OnDestroy,
} from '@angular/core';
import { MenuBarService } from '../../../services/menu-bar-service/manu-bar.service';
@Component({
  selector: 'app-tooltip',
  template: '<ng-content></ng-content>',
  styleUrls: ['./tooltip.component.css'],
  standalone:true,
})
export class TooltipComponent implements OnDestroy {
  @Input() tooltip!: string;
  private tooltipElement: HTMLElement | null = null;
  private hideTimeout: any;
  currencies = ['US$','CN¥','EU€','GB£'];
  constructor(private elementRef: ElementRef, private renderer: Renderer2,private menuBarService: MenuBarService) {}

  @HostListener('mouseenter')
  onMouseEnter() {
    clearTimeout(this.hideTimeout);
    this.showTooltip();
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.hideTooltip();
  }

   showTooltip():void {
    if (!this.tooltipElement) {
      this.createTooltip();
    }

    this.renderer.setStyle(this.tooltipElement, 'opacity', '1');
  }

   createTooltip():void {
    this.tooltipElement = this.renderer.createElement('div');
    this.tooltipElement!.innerText = this.tooltip;
    this.renderer.addClass(this.tooltipElement, 'custom-tooltip');
    Object.keys(this.menuBarService.languageListKeys).map((item)=>{
      if(item==this.tooltip){
        this.renderer.addClass(this.tooltipElement,'notranslate');
      }
    });
    this.currencies.map((item)=>{
      if(item==this.tooltip){
        this.renderer.addClass(this.tooltipElement,'notranslate');
      }
    })

   
    this.renderer.appendChild(document.body, this.tooltipElement);
    this.renderer.setStyle(this.tooltipElement, 'position', 'absolute');
    this.renderer.setStyle(this.tooltipElement, 'opacity', '0');

    this.renderer.listen(this.tooltipElement, 'mouseenter', () => {
      clearTimeout(this.hideTimeout);
    });

    this.renderer.listen(this.tooltipElement, 'mouseleave', () => {
      this.hideTooltip();
    });

    this.updateTooltipPosition();
  }

   updateTooltipPosition():void {
    const hostElem = this.elementRef.nativeElement;
    const rect = hostElem.getBoundingClientRect();
    const tooltipRect = this.tooltipElement!.getBoundingClientRect();

    let top = rect.bottom + window.scrollY + 1;
    let left = rect.left + (rect.width - tooltipRect.width) / 2;

    if (left + tooltipRect.width > window.innerWidth) {
      left = window.innerWidth - tooltipRect.width - 10;
    }
    if (left < 0) {
      left = 2;
    }

    this.renderer.setStyle(this.tooltipElement, 'top', `${top}px`);
    this.renderer.setStyle(this.tooltipElement, 'left', `${left}px`);
    this.renderer.setStyle(this.tooltipElement, 'opacity', '1');
  }

   hideTooltip():void {
    if (this.tooltipElement) {
      this.hideTimeout = setTimeout(() => {
        this.renderer.setStyle(this.tooltipElement, 'opacity', '0');
        setTimeout(() => {
          this.removeTooltip();
        }, 300); // Match the CSS transition duration
      }, 200); // Small delay to ensure smooth transition
    }
  }

   removeTooltip():void {
    if (this.tooltipElement) {
      this.renderer.removeChild(document.body, this.tooltipElement);
      this.tooltipElement = null;
    }
  }

  ngOnDestroy():void {
    clearTimeout(this.hideTimeout);
    this.removeTooltip();
  }
}
