<div class="popup-container">
  <div class="popup-header">
    <span>Pages List</span>
    <img class="cross-icon" src="../../../assets/Icons/cross-icon.svg" alt="cross-Icon" (click)="close()" />
  </div>
  <div class="custom-select-panel">
    <div class="dropdown-header">
      <div class="grid-container header-container" [style.gridTemplateColumns]="gridColumns">
        <div *ngFor="let column of displayedColumns" class="header-cell">{{ column }}</div>
      </div>
    </div>
    <div *ngFor="let page of pages" class="mat-option" (click)="selectPage(page)">
      <div class="grid-container" [style.gridTemplateColumns]="gridColumns">
        <div *ngFor="let column of displayedColumns" class="data-cell">{{ page[column] }}</div>
      </div>
    </div>
  </div>
</div>