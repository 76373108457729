<ng-template #content let-modal>
    <angular-window class="add-item-modal" style="background-color:white"  [dragHolder]="header">
      <div class="modal-header" #header>
        <span class="heading">Add/Edit-Formula-dialog </span>
      </div>
      <div class="edit-item-container">
        <form>
          <!-- First Row of Dropdowns -->
          <div class="form-group row-group first-group">
            <div class="first-half-width">
              <label class="font-style">URL-Type(s)</label>
              <select class="language-select">
                <option value="leafs">Select</option>
                <option value="other">Other Type</option>
              </select>
            </div>
            <div class="second-half-width">
                <label class="font-style">Qty</label>
                <input type="text" placeholder="" class="font-style input-field" name="url">
            </div>
            <img
          class="cross-icon"
          src="../../../assets/black-cross-icon.svg"
          alt="cross-Icon"
          (click)="modal.dismiss('Cross click')"
        />
          </div>
  
          <!-- Second Row of Dropdowns -->
          <div class="form-group row-group">
            <div class="first-half-width">
              <label class="font-style">Formula-Type(s)</label>
              <select class="language-select">
                <option value="select">Select</option>
                <option value="other">Other Type</option>
              </select>
            </div>
            <div class="second-half-width">
                <label class="font-style">Qty</label>
                <input type="text" placeholder="" class="font-style input-field" name="url">
            </div>
          </div>
  
          <!-- Buttons -->
          <div class="button-group">
            <div class="Button" type="button" (click)="onSave()">Save</div>
            <div type="button" class="Button" (click)="onCancel()">Cancel</div>
          </div>
        </form>
      </div>
    </angular-window>
  </ng-template>