import { Page } from './../../../modals/page.modal';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { MenuBarService } from '../../../services/menu-bar-service/manu-bar.service';
import { SheetService } from '../../../services/sheet-service/sheet.service';
@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss'],
})
export class TabBarComponent implements OnInit{
  tooltipTemplate:any;
  @Input() sheets!: any[];
  @Input() currentSheetIndex!: number;
  @Output() sheetSelected: EventEmitter<number> = new EventEmitter<number>();
  @Output() sheetDeleted: EventEmitter<number> = new EventEmitter<number>();
  @Output() sheetAdded: EventEmitter<void> = new EventEmitter<void>();
  
  sheet = this.menuBarService.AllPageData
  constructor(private menuBarService: MenuBarService,private sheetService:SheetService) {}
  ngOnInit(): void {
    this.selectSheet(this.currentSheetIndex);
  this.menuBarService.deletePage.subscribe((res) => {
    if(res) {
      this.deleteSheet(this.currentSheetIndex)
    }
  })
  this.selectedSheetPgRow()
  this.menuBarService.pgRows.subscribe(page => {
    if (page) {
      this.sheets.push(page); // Add newly opened page or edition to the tabs
      this.selectSheet(this.sheets.length-1);
    }
  });
  this.sheetService.addSheet$.subscribe((data) => {
    this.openSheetFromUrl(data);
  });
  }
  selectSheet(index: number) {
    this.currentSheetIndex = index;
    this.sheetSelected.emit(this.currentSheetIndex);
    this.selectedSheetPgRow()
  
    if(this.currentSheetIndex == index) {
      this.menuBarService.selectedId.next(this.sheets[index]?.page_id)
    }
  }

  deleteSheet(index: number) {
    const id = this.sheets[index].page_id;
    this.menuBarService.closePage(id);
    // this.menuBarService.closePage(this.sheets[index].page_id);
    if (this.sheets.length > 1) {
      this.sheets.splice(index, 1);
      this.currentSheetIndex = 0;
      if(index != 0) {
        if(this.sheets.length == index ) {
          this.selectSheet(index-1);
        }else {
          this.selectSheet(index); 
        }      
      } 
      this.selectedSheetPgRow()
    } else {
      alert('Cannot delete the only sheet.');
    }
  }

  addSheet() {
    const defaultPage = this.sheets[0];
    const newSheet = {
      ...defaultPage, 
    };
    console.log('Default Page',defaultPage);
    this.sheets.push(newSheet);
    this.selectSheet(this.sheets.length - 1);
    this.sheetAdded.emit();
    this.selectedSheetPgRow()

  }
  openSheetFromUrl(data:any) {
    const defaultPage = data;
    const newSheet = {
      ...defaultPage, 
    };
    console.log('Default Page',defaultPage);
    this.sheets.push(newSheet);
    this.selectSheet(this.sheets.length - 1);
    this.sheetAdded.emit();
    this.selectedSheetPgRow()
    console.log(data);
  }
    isDropdownOpen: boolean[]=[];

    toggleDropdown(index:number) {
      this.isDropdownOpen[index] = !this.isDropdownOpen[index];
      console.log(this.isDropdownOpen);
    }

  onKeyDown(event: any, index: any) {
    if (this.sheets[index + 1] != undefined) {
      if (event.key === 'Tab' && this.sheets.length > index) {
        this.currentSheetIndex = index + 1;
        this.sheetSelected.emit(index + 1);
        this.selectedSheetPgRow()

      }
    } else {
      event.preventDefault();
    }
  }
  //Shift + Tab to select sheet on left side.
  onShiftTabKeyPress(event: any, index: any) {     
    if (this.sheets[index - 1] != undefined) {
      if (this.sheets.length > index) {
        this.currentSheetIndex = index - 1;
        this.sheetSelected.emit(index - 1);
        this.selectedSheetPgRow();
      }
    } else {
      event.preventDefault();
    }
  }
  PgRowData:any;
  selectedSheetPgRow() {
    this.PgRowData = this.sheet[this.currentSheetIndex];
    this.menuBarService.pgRow.next(this.PgRowData);
  }
}
