import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ItemService } from '../../services/item-service/item.service';

@Component({
  selector: 'dropdown-dialog',
  templateUrl: './dropdown-dialog.component.html',
  styleUrls: ['./dropdown-dialog.component.css'],
})
export class DropdownDialogComponent implements OnInit {
  form!: FormGroup;
  closeResult: string = '';
  private modalRef!: NgbModalRef;
  valueChanged: string | null = null;
  selectedValue: string | null = null;
  selectedIndex: number = -1;
  finalValue: string = '';
  itemDropdownData: any[] = [];
  
  @ViewChild('content', { static: true }) content: any;

  constructor(
    private modalService: NgbModal,
    private itemService: ItemService,
    public dialogRef: MatDialogRef<DropdownDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    // Get dropdown data 
    this.itemDropdownData = this.itemService.getDropdownData();
    
    this.form = this.fb.group({
      itemDropdown: new FormControl('')
    });
    
    this.open(this.content);
  }
  
  open(content: any) {
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });
    this.modalRef.result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        this.onCancel();
        return 'by pressing ESC';
      case ModalDismissReasons.BACKDROP_CLICK:
        this.onCancel();
        return 'by clicking on a backdrop';
      default:
        return `with: ${reason}`;
    }
  }

  onSave(): void {
    const formData = JSON.parse(this.form.value.itemDropdown);
    this.dialogRef.close(formData.pageType);
    this.modalRef.dismiss('Save click');

    if (this.data.mode == 'Edit') {
      // Calling update item API
      this.itemService.updateCellItem(Object.assign(formData, {...this.data}));

    } else if (this.data.mode == 'Add') {
      // Calling add item API
      this.itemService.addCellItem(Object.assign(formData, {...this.data}));
    }
  }

  onCancel(): void {
    this.dialogRef.close();
    this.modalRef.dismiss('Cancel click');
  }
}
