<h1 mat-dialog-title>Open Edition</h1>
<div mat-dialog-content>
  <ul>
    <li *ngFor="let edition of editions" (click)="onOpenEdition(edition)">
      {{ edition }}
    </li>
  </ul>
</div>
<div mat-dialog-actions class="button-group">
  <div class="Button" (click)="onMoveEdition()">Move</div>
  <div class="Button" (click)="onClose()">Close</div>
</div>