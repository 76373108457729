<div class="menu-bars notranslate">
  <!-- Refresh Button -->
  <app-tooltip tooltip="{{ sessionId }}" >
  <div class="chip"  (mouseenter)="blinkRefresh()">
      <img src="./../../../../../../assets/Icons/reload-Icon.svg" alt="reload-Icon" [class.blink]="isBlinking"
        (click)="refreshPage()">
  </div>
</app-tooltip>

<app-tooltip tooltip="UI Region: {{ selectRegion }}" >
  <div class="chip" (click)="showCountryRegion()">
      <img src="./../../../../../../assets/Icons/country-Icon.svg" alt="country-icon"  >
  </div>
</app-tooltip>

  <app-tooltip tooltip="{{ tooltip }}">
  <div class="chip" [matMenuTriggerFor]="languageMenu">
    <img src="./../../../../../../assets/Icons/language-Icon.svg" alt="language-icon" >
    <input type="checkbox" id="languageCheckbox" name="languageCheckbox" class="checkbox"  [(ngModel)]="isChecked" (change)="toggleAutoTranslate($event)">

  </div>
</app-tooltip>
<mat-menu class="dropdown" #languageMenu="matMenu">
  <button [ngStyle]="{'background-color': tooltip == lang ? '#eeeef2' : '#fff'}" class="button-language notranslate" mat-menu-item *ngFor="let lang of filteredLanguages" (click)="setLanguage(lang)">
    {{ lang }}
  </button>
  <button class="button-language notranslate" mat-menu-item (click)="setLanguage(null)">None</button>
</mat-menu>
  <!-- currency -->
  <app-tooltip translate="no" tooltip="{{ uiCurrency }}" [matMenuTriggerFor]="currencyList">
  <div class="chip notranslate">
      <span >{{ uiCurrency }}</span>
    </div>
  </app-tooltip>
  <mat-menu #currencyList="matMenu" class="custom-menu-panel currency-list">
    <button 
      mat-menu-item 
      class="currency-button notranslate"
      *ngFor="let item of currencies"
      (click)="setCurrency(item)"> <!-- Directly use item.mode -->
      <span>{{ item }}</span>
    </button>
  </mat-menu>

  <!-- User -->
  <div class="chip" (click)="openUserDialog()">
    <span>User</span>
  </div>

  <app-tooltip tooltip="Page Name: All Pages
      Page Edition: Default
      Page Comment: Page: Listing of all Pages in the system. Said Page is displayed in the AllPage-dialog">
  <div class="chip" (contextmenu)="openContextMenu($event,'pageMenu')" (click)="openAllPagesDialog()">
      <div >
        <span>Page</span>
      </div>
  
    </div>
  </app-tooltip>
  <div class="page-menu-container" *ngIf="pageMenu">
    <div class="page-menu">
      <button mat-menu-item (click)="formatPage()">Format Page</button>
      <button mat-menu-item (click)="deletePage()">Delete Page</button>
      <button mat-menu-item (click)="viewPage()">View Page</button>
    </div>
  </div>

  <app-tooltip tooltip="Page Name: All Pages
  Page Edition: Default
  Page Comment: Page: Listing of all Page's in the system. Said Page is displayed in the AllPage-dialog">
  <div class="chip">
    <span (click)="openEditionDialog()">{{lastEditionopen}}</span>
    <input type="checkbox" id="editionCheckbox" name="editionCheckbox" class="checkbox"
    (change)="handleCheckboxChange($event)" [disabled]="!disabled" [(ngModel)]="EditionCheckbox">
  </div>
  </app-tooltip>

  <div class="chip" [matMenuTriggerFor]="viewMode">
    <span>{{ currentPgMode }}</span>
  </div>
  
  <mat-menu #viewMode="matMenu" class="custom-menu-panel language-list">
    <button 
      mat-menu-item 
      *ngFor="let item of contextMenuItems" 
      [ngClass]="{'dimmed': item.isDimmed}" 
      (click)="setViewMode(item.mode)"> <!-- Directly use item.mode -->
      {{ item.name }}
    </button>
  </mat-menu>
  
  <div class="chip" (click)="openColDialog()">
    <span >Cols</span>
  </div>
  <div class="chip" >
    <span style="display: contents;" >Freeze: <u>{{pageFreezeColumn}}</u></span>
    <img src="./../../../../../../assets/Icons/freeze-Icon.svg" class="img-design" alt="freeze-icon" (click)="toggleFreeze($event)">
  </div>

<div class="chip">
  <label for="expand-level-input" style="margin-bottom: 0px;">Expand:</label>
  <input type="text" class="expand-input" id="expand-level-input" [(ngModel)]="ExpandLevels.n" (input)="onExpandChange(ExpandLevels.n.toString())" autocomplete="off" />
  <span class="expand-toggle" (click)="onExpandToggle($event)">
    <img src="./../../../../../../assets/Icons/freeze-Icon.svg" alt="expand-icon" class="img-design">
  </span>
</div>
  <div class="chip" >
    <span>Sort</span>
    <input type="checkbox" id="sortCheckbox" name="sortCheckbox" class="checkbox" (change)="toggleSort()">
  </div>
  <div class="chip" >
    <span>Filter</span>
    <input type="checkbox" id="filterCheckbox" name="filterCheckbox" class="checkbox" (change)="toggleFilter()">
  </div>
  <div class="chip" >
    <span style="display: contents;" class="text-nowrap">Level-Set</span>
    <input type="checkbox" id="levelSetCheckbox" name="levelSetCheckbox" class="checkbox"
      (change)="handleCheckboxChange($event)">
  </div>
  <div class="chip" (click)="OpenAmountDialoag()">
    <span style="display: contents;" class="text-nowrap">Row-Set</span>
    <input type="checkbox" id="showSetCheckbox" name="showSetCheckbox" class="checkbox"
      (change)="handleCheckboxChange($event)">
  </div>
  <div class="chip" (click)="OpenJSONDialog()">
    <span>Cats</span>
  </div>
  <!-- Compare -->
  <div class="chip" (click)="OpenTextDialog()">
    <span>Compare</span>
    <input type="checkbox" id="compareCheckbox" name="compareCheckbox" class="checkbox" (change)="toggleCompare()">
  </div>
  <!-- timer -->
  <div class="chip" (click)="OpenFormulaDialog()">
    <span>Timer</span>
    <input type="checkbox" id="timerCheckbox" name="timerCheckbox" class="checkbox" (change)="toggleTimer()">
  </div>
  
</div>