
<ng-template #content let-modal>
    <angular-window class="window-container" style="background-color:white; border-radius: 4px; height: fit-content;" [dragHolder]="header">
      <div class="modal-header" #header>
        <span class="heading">Delete: Item (ID:1)</span>
      </div>
      <div class="edit-item-container">
      
        <div class="form-group">
          <label for="item-input font-style">{{data?.data}}</label>
        </div>
        <div class="button-group">
          <div  class="Button" (click)="onDelete()" >Delete</div>
          <div  class="Button" (click)="onCancel()" >Cancel</div>
        </div>
      </div>
    </angular-window>
  </ng-template>