<ng-template #content let-modal>
  <angular-window style="background-color: white" [dragHolder]="header">
    <div class="modal-header" #header>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click'); dialogRef.close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="edit-item-container">
      <div class="title-container">
        <span class="form-title">Format Row</span>
      </div>
      <form [formGroup]="Format_Row" (ngSubmit)="onSave()">
        <div class="form-group">
          <label>Format ID</label>
          <input
            matInput
            formControlName="Format_Id"
            class="custom-input"
            readonly
          />
        </div>

        <div class="form-group">
          <label>Format_User</label>
          <input matInput formControlName="Local_Row_ID" class="custom-input" />
        </div>
        <div class="form-group">
          <label>Local Row ID</label>
          <input
            matInput
            formControlName="Local_Row_ID"
            class="custom-input"
            readonly
          />
        </div>
        <div class="form-group">
          <label>Row Status</label>
          <input matInput formControlName="R_Status" class="custom-input" />
        </div>

        <div class="form-group">
          <label>Row Type</label>
          <input matInput formControlName="R_Type" class="custom-input" />
        </div>

        <div class="form-group">
          <label>Row Font-Style</label>
          <input
            matInput
            (click)="openFontDialog()"
            formControlName="R_Font_Style"
            class="custom-input"
          />
        </div>

        <div class="form-group">
          <label>Row Comment</label>
          <input matInput formControlName="R_Comment" class="custom-input" />
        </div>
        <div class="form-group">
          <label>Row Transactions</label>
          <input
            matInput
            formControlName="R_Transactions"
            class="custom-input"
            readonly
          />
        </div>
        <div class="button-group">
          <div class="Button">Save</div>
          <div
            type="button"
            class="Button"
            (click)="
              onCancel(); modal.dismiss('Cross click'); dialogRef.close()
            "
          >
            Cancel
          </div>
        </div>
      </form>
    </div>
  </angular-window>
</ng-template>
