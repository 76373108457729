<ng-template #content let-modal>
  <angular-window
    class="modal-user"
    style="background-color: white"
    [dragHolder]="header"
  >
    <div class="modal-header" #header>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click'); dialogRef.close()"
      >
        <img
          class="cross-icon"
          src="../../../assets/Icons/cross-icon.svg"
          alt="cross-Icon"
          (click)="onClose()"
        />
      </button>
    </div>
    <div class="edit-item-container">
      <div class="button-group">
        <div class="Button" (click)="login()">Login</div>
        <div class="Button" (click)="openProfile()">Profile</div>
        <div type="button" class="Button" (click)="logout()">Logout</div>
        <div type="button" class="Button" (click)="register()">
          Register
        </div>
        <div type="button" class="Button" (click)="closeDialog()">
          Close
        </div>
      </div>
    </div>
  </angular-window>
</ng-template>
